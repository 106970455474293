import { Button} from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import AuthenLayout from "../../components/layout/authen";
import { RootState } from "../../types/store";
import { useAppDisPatch } from "../../utils/common";
import { useSelector } from "react-redux";
import { getToken } from "../../store/auth/get-token/actions";

const SignInPage = () => {
  const dispatch = useAppDisPatch();
  const authStore = useSelector((state: RootState) => state.getToken);
  const navigate = useNavigate()

  const onGetToken = () => {
    dispatch(getToken(navigate));
  }

  return (
    <AuthenLayout>
      <div className="max-sm:p-4 p-10 font-rale-way">
        <h2 className="slogan text-center">
          <div className="flex items-start max-sm:text-[36px] max-lg:text-[50px] max-lg:h-[59px] max-sm:h-[42px] text-[64px] h-[75px] justify-center overflow-hidden max-sm:ml-[-15px] max-lg:ml-[-30px] ml-[-65px] gap-[10px] font-rale-way font-semibold">
            <div className="flex items-end flex-col justify-end text-pink-theme-1">
              <span className="spin-words-animation">Easiest</span>
              <span className="spin-words-animation">Fastest</span>
              <span className="spin-words-animation">Cheapest</span>
              <span className="spin-words-animation">Smartest</span>
              <span className="spin-words-animation">Easiest</span>
            </div>
            <p>way</p>
          </div>
          <div className="max-sm:text-xl max-lg:text-[30px] text-5xl leading-normal font-semibold">to send money online in <span className="text-pink-theme-1">Vietnam.</span>
          </div>
        </h2>
        <h4 className="text-gray-1 text-2xl font-semibold mb-10 mt-5 text-center">Launching in 2024</h4>
        <div className="mx-auto my-0 w-[100%]">
          <div className="overlay-gray rounded-xl px-4 pt-10 pb-4 mx-auto my-0 max-w-[420px]">
            <div className="flex items-center justify-center">
              <Button loading={authStore.isLoading} onClick={onGetToken} type='primary' className="h-input-sm sm:h-input-sm md:h-input rounded-[12px] bg-main-theme text-white font-bold max-sm:text-lg text-h5 font-rale-way">Get Started</Button>
            </div>
          </div>
        </div>
      </div>
    </AuthenLayout>
  )
};

export default SignInPage;
